import { Link } from 'react-router-dom';

export function HomePage() {
  return (
    <div className="relative bg-white overscroll-auto touch-auto">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <img
              className="h-11"
              src="https://s3.amazonaws.com/assets.scribblar.com/img/logos/scribblar_14365.png"
              alt="Scribblar"
            />
            <div className="hidden sm:mt-16 sm:flex lg:mt-8">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Discover how our platform can revolutionize your tutoring
                sessions -{' '}
                <Link
                  to="about"
                  className="whitespace-nowrap font-semibold text-pink-600"
                >
                  learn more today
                </Link>
              </div>
            </div>
            <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
              Transforming Online Tutoring for All
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Are you a tutor looking to enhance your online teaching
              experience? Look no further! Our innovative platform combines a
              collaborative whiteboard with real-time chat to create an
              interactive and engaging learning environment. Designed for tutors
              teaching students of all ages, our tools allow for seamless
              communication and dynamic instruction. Whether you're explaining
              complex math problems, illustrating science concepts, or guiding
              students through language exercises, our whiteboard lets you draw,
              write, and visualize ideas effortlessly. Paired with real-time
              chat, you can provide instant feedback and support, making
              learning more personalized and effective. Join us today and
              transform the way you teach online!
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              {/* TODO - this will go to rooms where they select a room e.g. test first
              for now assume room selected was test and go straight to the whiteboard */}
              <Link
                to="/room"
                className="rounded-md bg-pink-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
              >
                Get started
              </Link>
              <Link
                to="about"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src="https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

import '@tldraw/tldraw/tldraw.css';
import { useEffect, useState, useRef } from 'react';
import {
  Tldraw,
  DefaultMainMenu,
  TldrawUiMenuGroup,
  TldrawUiMenuItem,
  DefaultMainMenuContent,
  type TLComponents,
} from '@tldraw/tldraw';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom';
import {
  Button,
  Clipboard,
  Drawer,
  Modal,
  Popover,
  TextInput,
  Tooltip,
} from 'flowbite-react';
import { useSyncStore } from '../useSyncStore';
import * as Button from '../components/Button';

function UserButton() {
  return (
    <div className="flex p-2 gap-1.5">
      <Button.User />
    </div>
  );
}

function CustomMainMenu({ onShare }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const chat = searchParams.get('chat');

  function handleSelectChat() {
    const open = chat === 'true';
    setSearchParams({ chat: !open });
  }

  return (
    <DefaultMainMenu>
      <div>
        <TldrawUiMenuGroup id="custom-main-menu">
          <TldrawUiMenuItem
            id="chat"
            label="Chat"
            icon="external-link"
            readonlyOk
            onSelect={handleSelectChat}
          />
          <TldrawUiMenuItem
            id="share"
            label="Share"
            icon="share-1"
            readonlyOk
            onSelect={() => onShare(true)}
          />
        </TldrawUiMenuGroup>
      </div>
      <DefaultMainMenuContent />
    </DefaultMainMenu>
  );
}

const components: TLComponents = ({ onShare }) => ({
  MainMenu: () => <CustomMainMenu onShare={onShare} />,
  SharePanel: UserButton,
});

declare const PARTYKIT_HOST: string;

export const Canvas = ({ roomId, onShare }) => {
  const [openModal, setOpenModal] = useState(false);
  const urlInputRef = useRef<HTMLInputElement>(null);
  const url = window.location.href;

  const store = useSyncStore({
    host: PARTYKIT_HOST,
    room: roomId,
  });

  function onShare(x) {
    setOpenModal(x);
  }

  return (
    <>
      <Modal
        show={openModal}
        size="3xl"
        popup
        onClose={() => setOpenModal(false)}
        initialFocus={urlInputRef}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="grid w-full">
            <div className="relative">
              <label htmlFor="share-url" className="sr-only">
                Label
              </label>
              <input
                id="share-url"
                type="text"
                ref={urlInputRef}
                className="col-span-6 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                value={url}
                disabled
                readOnly
              />
              <Clipboard.WithIcon valueToCopy={url} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="tldraw__editor">
        <Tldraw autoFocus store={store} components={components({ onShare })} />
      </div>
    </>
  );
};

const faqs = [
  {
    question: 'How does the collaborative whiteboard work?',
    answer:
      'Our whiteboard allows tutors and students to draw, write, and share content in real-time, making lessons interactive and engaging.',
  },
  {
    question: 'Can I use the platform for any subject?',
    answer:
      'Yes, our platform is versatile and suitable for teaching all subjects to students of any age.',
  },
  {
    question: 'Is real-time chat available during sessions?',
    answer:
      'Absolutely! Our platform includes real-time chat for instant communication and feedback during tutoring sessions.',
  },
  {
    question: 'How do I get started with the platform?',
    answer:
      'Simply sign up on our website, set up your profile, and start scheduling your tutoring sessions right away.',
  },
];

export function AboutPage() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Frequently asked questions
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Can’t find the answer you’re looking for? Reach out to our{' '}
              <a
                href="#"
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                customer support
              </a>{' '}
              team.
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

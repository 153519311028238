import { useEffect, useState } from 'react';
import usePartySocket from 'partysocket/react';
import PartySocket from 'partysocket';
import {
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
  Outlet,
  redirect,
  useSearchParams,
} from 'react-router-dom';
import {
  Button,
  Drawer,
  Modal,
  Popover,
  TextInput,
  Tooltip,
} from 'flowbite-react';
import { HiOutlineUsers, HiPlus, HiMenu } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { useUser, useAuth } from '@clerk/clerk-react';
import { Temporal } from 'temporal-polyfill';
import { ChatMessage } from '../../party/utils/message.ts';
import { RoomInfo } from '../../party/rooms.ts';
import { Canvas } from './Canvas.tsx';
import { Chat } from './Chat.tsx';
import { User as UserButton } from '../components/Button';

const roomPath = (id) => `${window.location.origin}/parties/room/${id}`;

const identify = async (socket: PartySocket, { user, roomId }) => {
  const {
    id,
    username,
    primaryEmailAddress: { emailAddress },
    firstName,
    lastName,
    fullName,
  } = user;
  // the ./auth route will authenticate the connection to the partykit room
  const req = await fetch(`${roomPath(roomId)}/auth?_pk=${socket._pk}`, {
    method: 'POST',
    body: JSON.stringify({
      id,
      username,
      emailAddress,
      firstName,
      lastName,
      fullName,
    }),
  });

  if (!req.ok) {
    const res = await req.text();
    console.error('Failed to authenticate connection to PartyKit room', res);
  }
};

// Main rooms wrapper to load a room or admin page on authentication
export const Rooms = () => {
  const { isSignedIn, user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  let { roomId } = useParams();
  const isAdminUrl = location.pathname.endsWith('/admin');

  useEffect(() => {
    // If signed in and not on the admin page
    if (isSignedIn && !isAdminUrl) {
      // If no room id then navigate to a new room else
      // by default go to existing room using url id
      if (!roomId) {
        // Create (or enter) a new room named using the id appending
        // "-test" (just for now)
        // Take the unique user id and remove the user_ prefix
        const id = user.id.split('user_')[1];
        navigate(`/room/${id}-test`);
      }
    }
  }, [isSignedIn]);

  return (
    <div>
      {/* Placholder for content defined by browser router */}
      <Outlet />
    </div>
  );
};

// Unique room to dislay canvas/chat/etc
export const Room = () => {
  /**
   * The flow to get here is Get Started button click.
   * We could have them once signed in choose a room,
   * but for now let's just choose a room named test
   * and assume that they've selected it.
   *
   * roomId - userId-test for example for now...
   * Need to I think at this point make a request to the rooms
   * api and check if there's a room of that id, if not create it.
   * Then we use that throughout the rest of the session.
   *
   * Also need to add the current user (if new room then they're owner).
   * Show loader whilst all this is happening
   * */

  // State
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // Auth
  const { getToken, sessionId } = useAuth();
  const { user, isSignedIn } = useUser();
  // Route
  let { roomId } = useParams();
  
  const chat = searchParams.get('chat');

  let location = useLocation();

  useEffect(() => {
    const open = chat === 'true';
    setIsOpen(open);
  }, [chat]);

  // Connect to the room socket
  const socket = usePartySocket({
    party: 'room',
    room: roomId,
    onOpen(e) {
      // On socket open, if signed in then identify and add user to room user list
      if (isSignedIn) {
        identify(e.target, { user, roomId });
      }
    },
  });

  const handleClose = () => {
    setSearchParams({ chat: false });
  };

  return (
    <div className="fixed overflow-hidden inset-0 h-full w-full flex bg-white">
      <div className="flex h-full w-full">
        <div className="flex-1 z-0">
          <Canvas roomId={roomId} />
        </div>
        <div className={`${isOpen ? 'w-64' : 'w-0'}`}>
          <Drawer
            className="bg-gray-50 shadow-md w-64 p-0"
            backdrop={false}
            position="right"
            open={isOpen}
            onClose={handleClose}
          >
            <Chat roomId={roomId} />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

// If the update was successful, reload the user data
// await user.reload();

export const Admin = () => {
  // State
  const [rooms, setRooms] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  // Router
  const navigate = useNavigate();
  // Auth
  const { user, isSignedIn } = useUser();

  // Configured in Clerk for now
  const isAdmin = user?.publicMetadata.role === 'admin';

  if (!isAdmin) {
    navigate(`/`);
  }

  const socket = usePartySocket({
    party: 'rooms',
    room: 'list',
    onMessage(event) {
      if (isSignedIn) {
        const roomList = JSON.parse(event.data) as RoomInfo[];
        setRooms(roomList);
        if (selectedRoom?.id) {
          setSelectedRoom(roomList.find((x) => (x.id = selectedRoom?.id)));
        }
      }
    },
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  function handleOpenModal(room) {
    setSelectedRoom(room);
    setOpenModal(true);
  }

  async function handleDeleteCanvases(id) {
    const req = await fetch(`${roomPath(id)}/canvas`, {
      method: 'DELETE',
    });

    if (!req.ok) {
      const res = await req.text();
      console.error('Failed to delete room', res);
    }
  }

  async function handleDeleteMessages(id) {
    const req = await fetch(`${roomPath(id)}/chat`, {
      method: 'DELETE',
    });

    if (!req.ok) {
      const res = await req.text();
      console.error('Failed to delete messages', res);
    }
  }

  async function handleDeleteRoom(id) {
    // Party = rooms, Room = list
    const url = `${window.location.origin}/parties/rooms/list`;

    await handleDeleteMessages(id);
    await handleDeleteCanvases(id);

    const req = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        id,
        action: 'delete',
      }),
    });

    if (!req.ok) {
      const res = await req.text();
      console.error('Failed to delete room', res);
    }
  }

  function formatDateTime(str) {
    return str
      ? Temporal.Instant.from(str)
          .toZonedDateTimeISO(Temporal.Now.timeZoneId())
          .toLocaleString()
      : '-';
  }

  return (
    <div className="min-h-full">
      <div>
        <header className="bg-white border-b border-gray-200 mx-auto">
          <nav
            aria-label="Global"
            className="mx-auto flex items-center justify-between p-6 lg:px-8"
          >
            <div className="flex items-center gap-x-12">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src="https://s3.amazonaws.com/assets.scribblar.com/img/logos/scribblar_14365.png"
                  className="h-8 w-auto"
                />
              </a>
              <div className="hidden lg:flex lg:gap-x-12">
                {[].map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            <UserButton />
          </nav>
        </header>
        <main>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-h-[600px] overflow-y-auto bg-white p-5">
              <div className="py-6">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-2xl font-semibold leading-6 text-gray-900">
                        Rooms
                      </h1>
                      <p className="mt-2 text-sm text-gray-700">
                        A list of all the active rooms. Click the room users
                        count button to see a list of room users.
                      </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <Button
                        color="light"
                        size="sm"
                        disabled
                        onClick={() => handleOpenModal(room)}
                      >
                        <HiPlus className="mr-2 h-5 w-5" />
                        Add room
                      </Button>
                    </div>
                  </div>
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                              >
                                ID
                              </th>
                              <th
                                scope="col"
                                className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                              >
                                Users
                              </th>
                              <th
                                scope="col"
                                className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                              >
                                <span className="sr-only">Delete Canvases</span>
                              </th>
                              <th
                                scope="col"
                                className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                              >
                                <span className="sr-only">Delete Messages</span>
                              </th>
                              <th
                                scope="col"
                                className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                              >
                                <span className="sr-only">Delete Room</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {rooms.map((room, n) => (
                              <tr key={room.id}>
                                <td
                                  className={classNames(
                                    n !== rooms.length - 1
                                      ? 'border-b border-gray-200'
                                      : '',
                                    'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                                  )}
                                >
                                  {room.id}
                                </td>
                                <td
                                  className={classNames(
                                    n !== rooms.length - 1
                                      ? 'border-b border-gray-200'
                                      : '',
                                    'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell'
                                  )}
                                >
                                  {room.title}
                                </td>
                                <td
                                  className={classNames(
                                    n !== rooms.length - 1
                                      ? 'border-b border-gray-200'
                                      : '',
                                    'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                  )}
                                >
                                  <Button
                                    color="light"
                                    size="sm"
                                    onClick={() => handleOpenModal(room)}
                                  >
                                    <HiOutlineUsers className="mr-2 h-5 w-5" />
                                    {room?.users?.length ?? 0}
                                  </Button>
                                </td>
                                <td
                                  className={classNames(
                                    n !== rooms.length - 1
                                      ? 'border-b border-gray-200'
                                      : '',
                                    'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8'
                                  )}
                                >
                                  <button
                                    onClick={() =>
                                      handleDeleteCanvases(room.id)
                                    }
                                    className="text-pink-600 hover:text-pink-900"
                                  >
                                    Delete Canvases
                                    <span className="sr-only">, {room.id}</span>
                                  </button>
                                </td>
                                <td
                                  className={classNames(
                                    n !== rooms.length - 1
                                      ? 'border-b border-gray-200'
                                      : '',
                                    'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8'
                                  )}
                                >
                                  <button
                                    onClick={() =>
                                      handleDeleteMessages(room.id)
                                    }
                                    className="text-pink-600 hover:text-pink-900"
                                  >
                                    Delete Messages
                                    <span className="sr-only">, {room.id}</span>
                                  </button>
                                </td>
                                <td
                                  className={classNames(
                                    n !== rooms.length - 1
                                      ? 'border-b border-gray-200'
                                      : '',
                                    'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8'
                                  )}
                                >
                                  <button
                                    onClick={() => handleDeleteRoom(room.id)}
                                    className="text-pink-600 hover:text-pink-900"
                                  >
                                    Delete Room
                                    <span className="sr-only">, {room.id}</span>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Modal show={openModal} size="7xl" onClose={() => setOpenModal(false)}>
          <Modal.Header>Room: {selectedRoom?.id}</Modal.Header>
          <Modal.Body>
            <div className="p-8">
              <div className="flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full border-separate border-spacing-0">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            Username
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            First Name
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            Last Name
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            Email Address
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            Role
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                          >
                            Joined at
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                          >
                            Present
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                          >
                            Left at
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedRoom?.users.map((user, n) => (
                          <tr key={user.id}>
                            <td
                              className={classNames(
                                n !== selectedRoom.users.length - 1
                                  ? 'border-b border-gray-200'
                                  : '',
                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                              )}
                            >
                              <Tooltip content={user.id}>
                                {user.username}
                              </Tooltip>
                            </td>
                            <td
                              className={classNames(
                                n !== selectedRoom.users.length - 1
                                  ? 'border-b border-gray-200'
                                  : '',
                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                              )}
                            >
                              {user.firstName}
                            </td>
                            <td
                              className={classNames(
                                n !== selectedRoom.users.length - 1
                                  ? 'border-b border-gray-200'
                                  : '',
                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                              )}
                            >
                              {user.lastName}
                            </td>
                            <td
                              className={classNames(
                                n !== selectedRoom.users.length - 1
                                  ? 'border-b border-gray-200'
                                  : '',
                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                              )}
                            >
                              {user.emailAddress}
                            </td>
                            <td
                              className={classNames(
                                n !== selectedRoom.users.length - 1
                                  ? 'border-b border-gray-200'
                                  : '',
                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                              )}
                            >
                              {user.role}
                            </td>
                            <td
                              className={classNames(
                                n !== selectedRoom.users.length - 1
                                  ? 'border-b border-gray-200'
                                  : '',
                                'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell'
                              )}
                            >
                              {formatDateTime(user.joinedAt)}
                            </td>
                            <td
                              className={classNames(
                                n !== selectedRoom.users.length - 1
                                  ? 'border-b border-gray-200'
                                  : '',
                                'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                              )}
                            >
                              {user.present ? 'True' : 'False'}
                            </td>
                            <td
                              className={classNames(
                                n !== selectedRoom.users.length - 1
                                  ? 'border-b border-gray-200'
                                  : '',
                                'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell'
                              )}
                            >
                              {formatDateTime(user.leftAt)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

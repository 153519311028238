import { UserButton, useUser } from '@clerk/clerk-react';
import { HiShare } from 'react-icons/hi';
import { HiMenu } from 'react-icons/hi';

export function Chat({ onChat }) {
  function handleChat() {
    onChat();
  }

  return (
    <button
      onClick={handleChat}
      style={{ cursor: 'pointer', zIndex: 100000, pointerEvents: 'all' }}
    >
      <div className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded">
        <HiMenu />
      </div>
    </button>
  );
}

export function Share({ onShare }) {
  function handleShare() {
    onShare();
  }

  return (
    <button
      onClick={handleShare}
      style={{ cursor: 'pointer', zIndex: 100000, pointerEvents: 'all' }}
    >
      <div className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded">
        <HiShare />
      </div>
    </button>
  );
}

export function User() {
  const { isSignedIn } = useUser();

  if (!isSignedIn) {
    return null;
  }

  return (
    <UserButton
      appearance={{
        elements: {
          userButtonTrigger: {
            cursor: 'pointer',
            zIndex: 100000,
            pointerEvents: 'all',
          },
        },
      }}
    />
  );
}

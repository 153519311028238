import { useEffect, useState, useRef } from 'react';
import {
  Button,
  Drawer,
  Label,
  List,
  Textarea,
  TextInput,
} from 'flowbite-react';
import { HiChatBubbleLeftRight } from 'react-icons/hi2';
import { UserButton, useUser } from '@clerk/clerk-react';
import usePartySocket from 'partysocket/react';
import PartySocket from 'partysocket';
import { ChatMessage, ConnectionsUpdate } from '../../party/utils/message';

function msToTime(value) {
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  const d = new Date(value);
  return pad(d.getHours(), 2).concat(':', pad(d.getMinutes(), 2));
}

function trim(str, char = 10) {
  return str.slice(0, char);
}

const detectProtocolRegEx =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

const detectWWWRegEx = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

const replaceWithLink = (x, regex) =>
  x.replace(regex, `\u200b<a target="_blank" href='$1'>$1</a>\u200b`);

const replaceWithLinkAndProtocol = (x, regex) =>
  x.replace(
    regex,
    `$1\u200b<a target="_blank" class="textchatlink" href="http://$2">$2</a>\u200B`
  );

// Not prettiest but seems to work for now
function parseMessage(x) {
  let text = replaceWithLink(x, detectProtocolRegEx);
  text = replaceWithLinkAndProtocol(text, detectWWWRegEx);
  return text;
}

export function Chat({ roomId }) {
  const [messages, setMessages] = useState([]);
  const { isLoaded, isSignedIn, user } = useUser();

  const socket = usePartySocket({
    party: 'room',
    room: roomId,
    onMessage(event) {
      // console.log('event.data', JSON.parse(event.data) as ConnectionsUpdate);
      const message = JSON.parse(event.data) as ChatMessage;
      // Upon connection, the server will send all messages in the room
      if (message.type === 'sync') setMessages(message.messages);
      // After that, the server will send updates as they arrive
      if (message.type === 'new') setMessages((prev) => [...prev, message]);
      if (message.type === 'clear') setMessages([]);
      if (message.type === 'edit') {
        setMessages((prev) =>
          prev.map((m) => (m.id === message.id ? message : m))
        );
      }
      scrollToBottom();
    },
  });

  const inputRef = useRef(null);
  const messagesRef = useRef(null);

  function scrollToBottom() {
    setTimeout(() => {
      messagesRef.current.scrollTo({
        top: messagesRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 0);
  }

  function onSubmit(text) {
    const { username, firstName, fullName } = user;
    if (text?.trim()) {
      socket.send(
        JSON.stringify({
          type: 'new',
          text,
          from: { username, firstName, fullName },
        })
      );
      inputRef.current!.value = '';
      scrollToBottom();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(inputRef.current!.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <Drawer.Header
        theme={{
          inner: {
            titleIcon: 'me-2.5 h-4 w-4',
            titleText:
              'mb-3 inline-flex items-center text-base font-semibold text-gray-500 dark:text-gray-400',
          },
        }}
        title="chat"
        titleIcon={HiChatBubbleLeftRight}
        className="sticky top-0 bg-gray-100 px-3 pt-3 pb-0 pl-2"
      />
      <div ref={messagesRef} className="h-full flex-1 flex-col overflow-y-auto">
        <Drawer.Items className="h-full">
          <div className="flex-1">
            {!messages.length && (
              <div className="text-gray-400 pl-2.5 pt-2">
                No messages yet 😢
              </div>
            )}
            <div className="flex-1">
              <List className="list-none">
                {messages.map((message, n) => (
                  <List.Item key={message?.at ?? n}>
                    <a className="cursor-default block hover:bg-gray-100 focus:outline-none focus:bg-gray-50">
                      <div className="px-2 py-2 flex items-center">
                        <div className="min-w-0 flex-1">
                          <div>
                            <div className="text-sm leading-5 font-medium text-pink-600 truncate">
                              {message.from?.username ?? 'anonymous'}
                              <span className="ml-1 font-light text-xs text-gray-500">
                                {msToTime(message.at)}
                              </span>
                            </div>
                            <div className="mt-0 flex">
                              <div
                                className="flex items-center text-sm leading-5 text-gray-900 break-all"
                                dangerouslySetInnerHTML={{
                                  __html: parseMessage(
                                    message?.text ?? 'empty'
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </List.Item>
                ))}
              </List>
            </div>
          </div>
        </Drawer.Items>
      </div>
      <div>
        <form
          onKeyDown={handleKeyDown}
          onSubmit={handleSubmit}
          className="p-3 sticky bottom-0 bg-white"
        >
          <div className="mb-3">
            <Textarea
              ref={inputRef}
              id="message"
              name="message"
              placeholder="Your message..."
              rows={4}
              autoFocus
            />
          </div>
          <div className="flex">
            <Button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-700"
            >
              Send message
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
